<template>
    <div>
        <v-container fluid class="mb-8">
            <v-row align="center" class="mb-4" style="border-bottom: 1px dashed; border-color: #4f2684;"> 
                <v-subheader style="padding: 0px !important; font-size: 18px; font-weight: 400; color: #4f2684; margin-bottom: -8px;">Attachment</v-subheader>
            </v-row>
            <v-row align="center">
                <v-col cols="6" v-show="submit_selfie == true">
                    <div class="card-file-upload">
                        <label for="selfie_input" class="file-upload" @drop="drop('selfie')" @dragover="dragover" @dragleave="dragleave">
                            <input id="selfie_input" type="file" multiple hidden ref="selfie_file" accept="image/*"
                                    @change="onChange('selfie')"/>
                            <img :src="selfie_src" alt="" :style="inherit" v-if="selfie_src"/>
                            <div class="card-text" v-else>
                                <img v-show="!loading_selfie" src="@/assets/images/photo-camera.svg" style="width: 2.5rem;opacity: 0.5;">
                                <beat-loader style="margin-top: 12px; margin-bottom: 16px;" :loading="loading_selfie" color="#4f2684" size="12px"></beat-loader>
                                <p style="font-size: 10px;" class="p-1 mt-1" :class="{ attachmentlabel: error_selfie, attachmentlabelcolor: error_selfie_color }">Take a Selfie</p>
                            </div>
                            <div class="hover-file" v-if="selfie_src">
                                <div class="file-remove"  @click="handleDeleteAttachment('selfie')">
                                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                                </div>
                                <div>
                                    <br>
                                    <p class="file-replace"><br><br>Click to replace</p>
                                    <p class="file-replace" style="font-size: 10px;">Selfie</p>
                                </div>
                            </div>
                        </label>
                    </div>
                </v-col>
                <v-col cols="6" v-show="submit_id == true"> 
                    <div class="card-file-upload">
                        <label for="id_input" class="file-upload" @drop="drop('id')" @dragover="dragover" @dragleave="dragleave">
                            <input id="id_input" type="file" multiple hidden ref="id_file" accept="image/*"
                                    @change="onChange('id')"/>
                            <img :src="id_src" alt="" :style="inherit" v-if="id_src"/>
                            <div class="card-text" v-else>
                                <img v-show="!loading_id" src="@/assets/images/photo-camera.svg" style="width: 2.5rem;opacity: 0.5;">
                                <beat-loader style="margin-top: 12px; margin-bottom: 16px;" :loading="loading_id" color="#4f2684" size="12px"></beat-loader>
                                <p style="font-size: 10px;" class="p-1 mt-1" :class="{ attachmentlabel: error_id, attachmentlabelcolor: error_id_color }">Take a photo of your ID</p>
                            </div>
                            <div class="hover-file" v-if="id_src">
                                <div class="file-remove"  @click="handleDeleteAttachment('id')">
                                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                                </div>
                                <div>
                                    <br>
                                    <p class="file-replace"><br><br>Click to replace</p>
                                    <p class="file-replace" style="font-size: 10px;">ID</p>
                                </div>
                            </div>
                        </label>
                    </div>
                </v-col>  
            </v-row>
            <v-row align="center">
                <v-col cols="6" v-show="submit_coe == true">
                    <div class="card-file-upload">
                        <label for="coe_input" class="file-upload" @drop="drop('coe')" @dragover="dragover" @dragleave="dragleave">
                            <input id="coe_input" type="file" multiple hidden ref="coe_file" accept="image/*"
                                    @change="onChange('coe')"/>
                            <img :src="coe_src" alt="" :style="inherit" v-if="coe_src"/>
                            <div class="card-text" v-else>
                                <img v-show="!loading_coe" src="@/assets/images/photo-camera.svg" style="width: 2.5rem;opacity: 0.5;">
                                <beat-loader style="margin-top: 12px; margin-bottom: 16px;" :loading="loading_coe" color="#4f2684" size="12px"></beat-loader>
                                <p style="font-size: 10px;" class="p-1 mt-1" :class="{ attachmentlabel: error_coe, attachmentlabelcolor: error_coe_color }">Take a photo of your COE</p>
                            </div>
                            <div class="hover-file" v-if="coe_src">
                                <div class="file-remove"  @click="handleDeleteAttachment('coe')">
                                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                                </div>
                                <div>
                                    <br>
                                    <p class="file-replace"><br><br>Click to replace</p>
                                    <p class="file-replace" style="font-size: 10px;">coe</p>
                                </div>
                            </div>
                        </label>
                    </div>
                </v-col>
                <v-col cols="6" v-show="submit_payslip == true"> 
                    <div class="card-file-upload">
                        <label for="payslip_input" class="file-upload" @drop="drop('payslip')" @dragover="dragover" @dragleave="dragleave">
                            <input id="payslip_input" type="file" multiple hidden ref="payslip_file" accept="image/*"
                                    @change="onChange('payslip')"/>
                            <img :src="payslip_src" alt="" :style="inherit" v-if="payslip_src"/>
                            <div class="card-text" v-else>
                                <img v-show="!loading_payslip" src="@/assets/images/photo-camera.svg" style="width: 2.5rem;opacity: 0.5;">
                                <beat-loader style="margin-top: 12px; margin-bottom: 16px;" :loading="loading_payslip" color="#4f2684" size="12px"></beat-loader>
                                <p style="font-size: 10px;" class="p-1 mt-1" :class="{ attachmentlabel: error_payslip, attachmentlabelcolor: error_payslip_color }">Take a photo of your Payslip</p>
                            </div>
                            <div class="hover-file" v-if="payslip_src">
                                <div class="file-remove"  @click="handleDeleteAttachment('payslip')">
                                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                                </div>
                                <div>
                                    <br>
                                    <p class="file-replace"><br><br>Click to replace</p>
                                    <p class="file-replace" style="font-size: 10px;">Payslip</p>
                                </div>
                            </div>
                        </label>
                    </div>
                </v-col>
            </v-row>

            <v-row align="center">
                <v-col>
                    <v-checkbox @change="error_checkbox = false, error_checkbox_color = false" v-model="terms_and_conditions_checkbox" color="csb_yellow">
                        <template v-slot:label>
                            <div :class="{ checkbox_label: error_checkbox, checkbox_color: error_checkbox_color }">
                            I agree to the
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <router-link v-on:click.native="tickCheckbox()" v-on="on" to="/terms-and-conditions">
                                            Private Information Provisions for Loan Application
                                        </router-link>
                                    </template>
                                    Opens in new page
                                </v-tooltip>
                            </div>
                        </template>
                    </v-checkbox>
                </v-col>
            </v-row>
          
            <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                >
                {{ text }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                    >
                    Close
                    </v-btn>
                </template>
            </v-snackbar>
        </v-container>

        <v-row  align="center" justify="space-around" class="bottom-nav">
            <v-col v-show="resubmit == false" class="bottom-nav-item">
                <v-btn @click="handleBack()" depressed class="bottom-nav-btn back-btn">
                    Back
                </v-btn>
            </v-col>
            <v-col class="bottom-nav-item">
                <v-btn @click="handleSubmit()" depressed class="bottom-nav-btn">
                    <v-progress-circular v-if="submitted"
                    :size="20"
                    indeterminate
                    color="csb_purple"
                ></v-progress-circular>
                <span v-else>SUBMIT</span>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {
    mdiDelete,
  } from '@mdi/js'

import { submitMariaApplication, submitMariaAttachment, chatFuel } from '@/api/attachment'
import { mapGetters } from 'vuex'

import imageCompression from 'browser-image-compression'
import BeatLoader from 'vue-spinner/src/PulseLoader.vue'

import moment from 'moment'


export default {
    computed: {
        ...mapGetters([
            'calculator',
            'profile',
            'address',
            'resubmit',
            'fb_fname',
            'fb_lname',
            'fb_messengerid',
            'fb_gender',
            'fb_profilepic'
        ]),
    },
    components: {
        BeatLoader
    },            
    data: function() {
        return{
            coe: [],
            payslip: [],
            selfie: [],
            id: [],

            inherit: 'height:inherit',
            coe_src: null,
            coe_file: {},
            id_src: null,
            id_file: {},
            payslip_src: null,
            payslip_file: {},
            selfie_src: null,
            selfie_file: {},

            submitted: false,

            icons: {
                mdiDelete,
            },

            error_selfie: false,
            error_coe: false,
            error_payslip: false,
            error_id: false,

            error_selfie_color: false,
            error_coe_color: false,
            error_payslip_color: false,
            error_id_color: false,

            snackbar: false,
            text: 'Only images are accepted as signature.',
            timeout: 2000,

            error_files: [],
            submitted_sucessfully: [],

            submit_coe: true,
            submit_payslip: true,
            submit_selfie: true,
            submit_id: true,

            submit_maria_application: {},

            loading_coe: false,
            loading_payslip: false,
            loading_selfie: false,
            loading_id: false,

            error_checkbox: false,
            error_checkbox_color :false,
            terms_and_conditions_checkbox: false,

        }
    },
    methods:{
        handleBack(){
            this.$router.push({ path: '/address/index' })
        },
        delay(ms) {
            return new Promise((resolve) => {
                setTimeout(resolve, ms);
            });
        },
        handleTermsAndConditions(){
            this.$router.push({ path: '/terms-and-conditions' })
        },
        // To prevent ticking checkbox upon clicking terms and conditions
        tickCheckbox(){
            this.terms_and_conditions_checkbox = !this.terms_and_conditions_checkbox
        },
        validateCheckbox(){
            if(this.terms_and_conditions_checkbox == true){
                return true
            } else {
                this.error_checkbox = true
                this.error_checkbox_color = true
                setTimeout(() => {
                    this.error_checkbox = false
                }, 500)
                return false
            }
        },
        async handleSubmit(){

            let valid_attachment = this.validateAttachment()
            let valid_checkbox = this.validateCheckbox()
    
            if( valid_attachment && valid_checkbox ){
                var submit_data_maria = {
                    "product_type": "SUC", 
                    "name_of_employer": this.calculator.schoolInput, 
                    "employer_sysid": this.calculator.schoolId, 
                    "nthp": this.calculator.nthp,
                    "loan_amount": this.calculator.loan_amount, 
                    "term": this.calculator.payment_term,
                    "monthly_ammortization": this.calculator.monthly_amo, 
                    "interest": this.calculator.rate, 
                    "fullname": `${this.profile.last_name}, ${this.profile.first_name} ${this.profile.middle_name}`,
                    "first_name": this.profile.first_name,
                    "middle_name": this.profile.middle_name,
                    "last_name": this.profile.last_name,
                    "dob": moment(this.profile.dob).format('YYYY-MM-DD'),
                    "gender": this.profile.gender,
                    "civil_status": this.profile.civil_status,
                    "mobile_no": this.profile.mobile_no,
                    "email_address": this.profile.email_address,
                    "province": this.address.province, 
                    "city": this.address.city, 
                    "barangay": this.address.barangay, 
                    "street_address": this.address.street,
                    "sof":"Employed", 
                    "employer_id":"", 
                    "employee_id":"", 
                    "down_payment":0.00, 
                    "monthly_salary":0.00, 
                    "fb_fname": this.fb_fname, 
                    "fb_lname": this.fb_lname, 
                    "fb_messengerid": this.fb_messengerid, 
                    "fb_gender": this.fb_gender, 
                    "fb_profilepic": this.fb_profilepic,
                    "email_verified":"No",
                    "loan_details_1":"", 
                    "loan_details_2":"", 
                    "loan_details_3":"", 
                    "loan_details_4":"", 
                    "work_province":"25", 
                    "work_city":"361", 
                    "work_barangay":"41936", 
                    "work_street":"Pres. Osmena Blvd cor. P. Burgos St.",

                    "promo_code":this.calculator.promo_code,

                    "position_title": this.profile.position_title
                }

                this.$store.dispatch('user/overlay', true)
                this.submitted = true

                if(this.resubmit == false) {
                
                
                    // submit to 'maria/deped',
                    this.$store.dispatch('user/overlayText', 'Saving application.')
                    this.submit_maria_application = await this.handleSubmitMariaApplication(submit_data_maria)
                    if(this.submit_maria_application.status == "success") {

                        this.application_id = this.submit_maria_application.application_id
                    
                        this.submitAttachments()

                    } else {
                        this.$store.dispatch('user/overlayText', 'Maria application submission failed!')
                        await this.delay(2000);
                        this.$store.dispatch('user/overlay', false)
                        this.submitted = false
                    }  

                } else { // if submission fails
                    this.submitAttachments()
                }
            }
        
        },
        async submitAttachments(){
            var upload_files = {}
            var error_count = 0

            var total_attachment = 0

            if(this.resubmit == false){
                total_attachment = 4
            } else {
                total_attachment = this.error_files.length
            }

             // selfie
            if(this.submit_selfie == true) {
                var msg1 = ''
                if(this.resubmit == false) {
                    msg1 = 'Uploading attachment 1/' + total_attachment + ': Selfie'
                } else {
                    var index1 = this.error_files.findIndex(i => i == 'selfie' )
                    msg1 = 'Uploading attachment ' + (index1+1) + '/' + total_attachment + ': Selfie'
                }

                this.$store.dispatch('user/overlayText', msg1) 
                var selfie = this.selfie_file
                var selfie_data = {
                    file: selfie,
                    sys_id: this.submit_maria_application.sys_id,
                    description: 'selfie'
                }

                upload_files.selfie = await this.handleSubmitMariaAttachment(selfie_data)
            }

            //ID
            if(this.submit_id == true) {
                var msg6 = ''
                if(this.resubmit == false) {
                    msg6 = 'Uploading attachment 2/' + total_attachment + ': ID'
                } else {
                    var index6 = this.error_files.findIndex(i => i == 'id' )
                    msg6 = 'Uploading attachment ' + (index6+1) + '/' + total_attachment + ': ID'
                }

                this.$store.dispatch('user/overlayText', msg6)
                var id = this.id_file
                var id_data = {
                    file: id,
                    sys_id: this.submit_maria_application.sys_id,
                    description: 'id'
                }
                
                upload_files.id = await this.handleSubmitMariaAttachment(id_data)
            }

            // coe
            if(this.submit_coe == true) {
                var msg3 = ''
                if(this.resubmit == false) {
                    msg3 = 'Uploading attachment 3/' + total_attachment + ': coe'
                } else {
                    var index3 = this.error_files.findIndex(i => i == 'coe' )
                    msg3 = 'Uploading attachment ' + (index3+1) + '/' + total_attachment + ': coe'
                }

                this.$store.dispatch('user/overlayText', msg3)
                var coe = this.coe_file
                var coe_data = {
                    file: coe,
                    sys_id: this.submit_maria_application.sys_id,
                    description: 'coe'
                }
                
                upload_files.coe = await this.handleSubmitMariaAttachment(coe_data)
            }

            // Payslip
            if(this.submit_payslip == true) {
                var msg4 = ''
                if(this.resubmit == false) {
                    msg4 = 'Uploading attachment 4/' + total_attachment + ': Payslip'
                } else {
                    var index4 = this.error_files.findIndex(i => i == 'payslip' )
                    msg4 = 'Uploading attachment ' + (index4+1) + '/' + total_attachment + ': Payslip'
                }

                this.$store.dispatch('user/overlayText', msg4)
                var payslip = this.payslip_file
                var payslip_data = {
                    file: payslip,
                    sys_id: this.submit_maria_application.sys_id,
                    description: 'payslip'
                }
                
                upload_files.payslip = await this.handleSubmitMariaAttachment(payslip_data)
            }

            // clear error_files and submitted_sucessfully
            this.error_files = []
            this.submitted_sucessfully = []

            Object.keys(upload_files).forEach(key => {
                if(!upload_files[key]){
                    this.error_files.push(key)
                    error_count++
                }
            })

            if(error_count==0){
                await this.callChatFuel()
                this.$store.dispatch('user/overlayText', 'Application successfully submitted!') 
                await this.delay(2000);
                this.$store.dispatch('user/overlay', false)
                this.submitted = false
                this.$router.replace({ path: '/last-page' })            
                
            }else{
                var file_text = (error_count==1) ? ' file ' : ' files '
                var err_msg = 'Created user but error on uploading files.' + error_count + file_text +'('+ this.error_files.join(', ') +')'
                this.$store.dispatch('user/overlayText', 'Application attachment submission failed - ' + err_msg)
                await this.delay(2000);
                this.$store.dispatch('user/overlay', false)
                this.$store.dispatch('user/submitted', true)
                this.submitted = false
                
                this.$store.dispatch('user/resubmit', true) // resubmit

                Object.keys(upload_files).forEach(key => {
                    if(upload_files[key]){
                        this.submitted_sucessfully.push(key)
                    }

                    this.deleteAttachment(key)
                })

                this.error_files.forEach(i => {
                    switch(i) {
                        case 'coe':
                            this.submit_coe = true
                            break;
                        case 'payslip':
                            this.submit_payslip = true
                            break;
                        case 'selfie':
                            this.submit_selfie = true
                            break;
                        case 'id':
                            this.submit_id = true
                            break;             
                        default:
                            // default block
                    }
                })

                this.submitted_sucessfully.forEach(i => {
                    switch(i) {
                        case 'coe':
                            this.submit_coe = false
                            break;                    
                        case 'payslip':
                            this.submit_payslip = false
                            break;
                        case 'selfie':
                            this.submit_selfie = false
                            break;
                        case 'id':
                            this.submit_id = false
                            break;                    
                        default:
                            // default block
                    }
                })
            }
        },
        handleSubmitMariaApplication: async function(submit_data){
            var application_status = {}
            await submitMariaApplication(submit_data).then(async response => {
                application_status.status = "success"
                application_status.sys_id = response.data.data.sys_id
                application_status.application_id = response.data.data.application_id
            }).catch(error => {
                // this.$log.error(error);
                this.snackbar = true
                this.text = 'error saving ' + error
                application_status.status = "error"
            });

            return application_status
        },
        handleSubmitMariaAttachment: async function(file_data){
            var status = false

            var formData = new FormData();

            formData.append("file", file_data.file);
            formData.append("app_sys_id", file_data.sys_id);
            formData.append("description", file_data.description); 

            await submitMariaAttachment(formData).then(() => {
                status = true
            }).catch(error => {
                // this.$log.error(error);
                this.snackbar = true
                this.text = 'error saving ' + error
                status = false
            });

            return status
        },
        callChatFuel: async function(){

            var submit_data={
                "application_id": this.application_id,
                "messenger_id": this.fb_messengerid
            }

            chatFuel(submit_data).catch(error => {
                this.$log.error(error);
            });
        },
        onProgress(attachment){
            this[attachment] = true
        },
        blobToFile(theBlob, fileName){       
            return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
        },
        async onChange(type) {
            event.preventDefault();
            var reader = ''
            var file_type = null
            var input = null
            switch (type) {
                case 'coe':
                    if(this.$refs.coe_file.files.length){
                        file_type = this.$refs.coe_file.files[0].type

                        if(file_type.includes('image')){
                            var coe_file = this.$refs.coe_file.files[0];
                            const options = {
                                maxSizeMB: 1,
                                maxWidthOrHeight: 1024,
                                useWebWorker: true,
                                onProgress: () => this.onProgress('loading_coe')
                            }
                            this.coe_file = this.blobToFile(await imageCompression(coe_file, options), coe_file.name);
                            reader = new FileReader();
                            reader.onload = () => {
                                this.coe_src = reader.result
                            };
                            reader.readAsDataURL(this.coe_file);
                            this.error_coe_color = false
                            this.loading_coe = false
                        }else{
                            input = this.$refs.coe_file
                            input.type = 'text'
                            input.type = 'file'
                            this.snackbar = true
                        }
                    }
                    break;
                case 'id':
                    if(this.$refs.id_file.files.length){
                        file_type = this.$refs.id_file.files[0].type

                        if(file_type.includes('image')){
                            var id_file = this.$refs.id_file.files[0];
                            const options = {
                                maxSizeMB: 1,
                                maxWidthOrHeight: 1024,
                                useWebWorker: true,
                                onProgress: () => this.onProgress('loading_id')
                            }
                            this.id_file = this.blobToFile(await imageCompression(id_file, options), id_file.name);
                            reader = new FileReader();
                            reader.onload = () => {
                                this.id_src = reader.result
                            };
                            reader.readAsDataURL(this.id_file);
                            this.error_id_color = false
                            this.loading_id = false
                        }else{
                            input = this.$refs.id_file
                            input.type = 'text'
                            input.type = 'file'
                            this.snackbar = true
                        }
                    }
                    break;
                case 'payslip':
                    if(this.$refs.payslip_file.files.length){
                        file_type = this.$refs.payslip_file.files[0].type

                        if(file_type.includes('image')){
                            var payslip_file = this.$refs.payslip_file.files[0];
                            const options = {
                                maxSizeMB: 1,
                                maxWidthOrHeight: 1024,
                                useWebWorker: true,
                                onProgress: () => this.onProgress('loading_payslip')
                            }
                            this.payslip_file = this.blobToFile(await imageCompression(payslip_file, options), payslip_file.name);
                            reader = new FileReader();
                            reader.onload = () => {
                                this.payslip_src = reader.result
                            };
                            reader.readAsDataURL(this.payslip_file);
                            this.error_payslip_color = false
                            this.loading_payslip = false

                            // show additional payslip 1
                            this.showAdditionalPayslip1 = true
                        }else{
                            input = this.$refs.payslip_file
                            input.type = 'text'
                            input.type = 'file'
                            this.snackbar = true
                        }
                    }
                    break; 
                case 'selfie':
                    if(this.$refs.selfie_file.files.length){
                        file_type = this.$refs.selfie_file.files[0].type

                        if(file_type.includes('image')){
                            var selfie_file = this.$refs.selfie_file.files[0];
                            const options = {
                                maxSizeMB: 1,
                                maxWidthOrHeight: 1024,
                                useWebWorker: true,
                                onProgress: () => this.onProgress('loading_selfie')
                            }
                            this.selfie_file = this.blobToFile(await imageCompression(selfie_file, options), selfie_file.name);
                            reader = new FileReader();
                            reader.onload = () => {
                                this.selfie_src = reader.result
                            };
                            reader.readAsDataURL(this.selfie_file);
                            this.error_selfie_color = false
                            this.loading_selfie = false
                        }else{
                            input = this.$refs.selfie_file
                            input.type = 'text'
                            input.type = 'file'
                            this.snackbar = true
                        }
                    }
                    break;   
                default:
                    break;
            }
        },
        drop(type) {
            event.preventDefault();
            var file_type = null
            file_type = event.dataTransfer.files[0].type
            switch (type) {
                case 'coe':
                    if(file_type.includes('image')){
                        this.$refs.coe_file.files = event.dataTransfer.files;
                        this.onChange('coe');
                    }else{
                        this.snackbar = true
                    }
                    break;
                case 'id':
                    if(file_type.includes('image')){
                        this.$refs.id_file.files = event.dataTransfer.files;
                        this.onChange('id');
                    }else{
                        this.snackbar = true
                    }
                    break;
                case 'payslip':
                    if(file_type.includes('image')){
                        this.$refs.payslip_file.files = event.dataTransfer.files;
                        this.onChange('payslip');
                    }else{
                        this.snackbar = true
                    }
                    break;
                case 'selfie':
                    if(file_type.includes('image')){
                        this.$refs.selfie_file.files = event.dataTransfer.files;
                        this.onChange('selfie');
                    }else{
                        this.snackbar = true
                    }
                    break;
                default:
                    break;
            }
        },
        dragover() {
            event.preventDefault();
        },
        dragleave() {
            event.preventDefault();
        },
        handleDeleteAttachment: function(type) {
            event.preventDefault();
            this.deleteAttachment(type)
        },
        deleteAttachment(type){
            var input = null
            switch (type) {
                case 'coe':
                    this.coe_file = {}
                    this.coe_src = null
                    input = this.$refs.coe_file
                    input.type = 'text'
                    input.type = 'file'
                    break;

                case 'id':
                    this.id_file = {}
                    this.id_src = null
                    input = this.$refs.id_file
                    input.type = 'text'
                    input.type = 'file'
                    break;

                case 'payslip':
                    this.payslip_file = {}
                    this.payslip_src = null
                    input = this.$refs.payslip_file
                    input.type = 'text'
                    input.type = 'file'
                    break;

                case 'selfie':
                    this.selfie_file = {}
                    this.selfie_src = null
                    input = this.$refs.selfie_file
                    input.type = 'text'
                    input.type = 'file'
                    break;

                default:
                    break;
            }
        },
        validateAttachment(){
            var valid = true

            if( this.submit_coe == true && !(this.coe_file instanceof File) ){
                this.error_coe = true
                this.error_coe_color = true
                setTimeout(() => {
                    this.error_coe = false
                }, 500)

                valid = false
            }

            if( this.submit_id == true && !(this.id_file instanceof File) ){
                this.error_id = true
                this.error_id_color = true
                setTimeout(() => {
                    this.error_id = false
                }, 500)

                valid = false
            }

            if( this.submit_payslip == true && !(this.payslip_file instanceof File) ){
                this.error_payslip = true
                this.error_payslip_color = true
                setTimeout(() => {
                    this.error_payslip = false
                }, 500)

                valid = false
            }
            
            if( this.submit_selfie == true && !(this.selfie_file instanceof File) ){
                this.error_selfie = true
                this.error_selfie_color = true
                setTimeout(() => {
                    this.error_selfie = false
                }, 500)

                valid = false
            }

            return valid
        },
        tosubmit(){
            this.$emit('submit')
        }
    },
}
</script>

<style scoped>
    img{
        max-width: 100%
    }
</style>